// i18next-extract-mark-ns-start terms

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';

const TermsPage = ({
	data,
	pageContext: {
		breadcrumb: { crumbs },
	},
}: {
	data:any;
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
}) => {
	
	return (
		<Page>
			<SEO
				title="Terms &amp; conditions | Realis Simulation"
				description=""
			/>

			<Banner
				title="Terms &amp; conditions"
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<h2>
						Website Terms of Use (&ldquo;Terms and Conditions&rdquo;)
					</h2>
					<p>
						Effective January 6, 2023
					</p>
					<p>
						Please read the Terms and Conditions carefully as they affect your rights and liabilities under the law.
					</p>
					<p>
						These Terms and Conditions refer to the following, which also apply to your use of our website:
					</p>
					<p>
						<ul>
							<li>
								Our {' '} <a href="https://www.realis-simulation.com/privacy-notice/">Privacy Notice</a>{' '}
								which sets out information about the cookies on our website and the terms on
								which we process any personal data we collect from you, or that you provide to us.
							</li>
						</ul>
					</p>
					<h3>
						1. USE OF THE WEBSITE
					</h3>
					<p>
						1.1 These Terms and Conditions govern your use of the website and all other Knowledge Services provided in connection with the website.
					</p>
					<p>
						1.2 By using the Website you confirm that you have accepted these Terms and Conditions and that you agree to comply with them.
						If you do not agree to these Terms and Conditions, please do not use the website.
					</p>
					<h3>
						2. AMENDMENTS
					</h3>
					<p>
						2.1 We may change these Terms and Conditions from time to time and any changes will be posted to this page.
						Please check back to see any changes or updates.
					</p>
					<h3>
						3. REGISTRATION
					</h3>
					<p>
						3.1 You may access most areas of the website without registering your details with us.
						Our customer support areas of the website are only open to you if you register.
					</p>
					<p>
						3.2 To register with the customer support section of the website you must be over eighteen years of age.
					</p>
					<p> 3.3 Each registration is for a single user only.
						We do not permit you to share your username and password with any other person.
					</p>
					<p>
						3.4 You must ensure that the details you provide on registration or at any time are correct and complete.
					</p>
					<h3>
						4. PASSWORD AND SECURITY
					</h3>
					<p>
						4.1 When you register to use the website you will be asked to create a password.
						You must keep this password confidential and must not disclose it or share it with anyone.
					</p>
					<p>
						4.2 You are responsible for all activities and orders that occur or are placed under your account.
						If you know or suspect that someone else knows your password, you should immediately notify us by contacting the Realis Simulation by email to
						{' '} <a href="mailto:contact@realis-simulation.com">contact@realis-simulation.com</a>.
					</p>
					<p>
						4.3 If we have reason to believe that there is or there is likely to be a breach of security or misuse of the website,
						we may require you to change your password or we may suspend your account in accordance with Clause 8 below.
					</p>
					<h3>
						5. INTELLECTUAL PROPERTY
					</h3>
					<p>
						5.1 The content of the website (including without limitation photographs and graphical images)
						is protected by copyright, trademarks, database and other intellectual property rights.
					</p>
					<p>
						5.2 Unless otherwise stated, the intellectual property rights in all material on the website is owned by
						Realis Simulation or a member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries,
						as defined in section 1159 of the UK Companies Act 2006 (&lsquo;<b>Associated Companies</b>&rsquo;).
					</p>
					<p>
						5.3 You may print off one copy and download extracts from the website for your own use and you may draw the attention of
						others within your organisation to content posted on our website.
					</p>
					<p>
						5.4 You must not modify the paper or digital copies of any materials you have printed off or downloaded from the website in any way,
						and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
					</p>
					<p>
						5.5 Realis Simulation&rsquo;s copyright and trademark notices and this permission (Clause 5 Intellectual property) notice must appear on all copies.
					</p>
					<p>
						5.6 You must not use any part of the content of our website for commercial purposes unless you obtain a licence from us to do so.
					</p>
					<p>
						5.7 If you print off, copy or download any part of our website in breach of these Terms and Conditions, your right to use our website will cease immediately
						and you must, at our option, return or destroy any copies of the materials you have made.
					</p>
					<p>
						5.8 No licence is granted to you in these Terms and Conditions to use any trade mark of Realis Simulation or any of our Associated Companies.
					</p>
					<h3>
						6. LIMITATIONS
					</h3>
					<p> 6.1 Other than personally identifiable information, which is covered by our
						{' '} <a href="https://www.realis-simulation.com/privacy-notice/">Privacy Notice</a>,
						any material you transmit or post to our website shall be considered non-confidential and non-proprietary.
						We shall have no obligations in respect to such material and we shall have the right to copy,
						disclose and distribute to third parties any such content for any purpose.
					</p>
					<p>
						6.2 You may not use our website for any of the following purposes:
					</p>
					<p>
						<ul>
							<li>In any way that breaches any applicable local, national or international law or regulation or code of practice;</li>
							<li>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect;</li>
							<li>Interfering with any other person's use or enjoyment of our website;</li>
							<li>To transmit or procure the sending of any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam);</li>
							<li>Interfering or disrupting networks, software or websites connected to our website; or</li>
							<li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware,
								adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software, hardware or website.</li>
						</ul>
					</p>
					<p>
						6.3 We reserve the right to refuse to post material on our website or to remove material already posted.
					</p>
					<p>
						6.4 We shall fully co-operate with any law enforcement authorities or court order requesting or directing us
						to disclose the identity or locate anyone posting any material in breach of Clause 6.2.
					</p>
					<p> 6.5 You will indemnify us against all losses, liabilities, costs and expenses reasonably suffered or incurred by us,
						all damages awarded against us under any judgment by a court of competent jurisdiction
						and all settlements sums paid by us as a result of any settlement agreed by us arising out or in connection with:
					</p>
					<p>
						<ul>
							<li>Any claim by any third party that the use of our website by you is defamatory, offensive or abusive, or of an obscene or pornographic nature,
								or is illegal or constitutes a breach of any applicable law, regulation or code of practice;</li>
							<li>Any claim by any third party that the use of our website by you infringes a third party's copyright or other intellectual property rights of whatever nature; and</li>
							<li>Any fines or penalties imposed by any regulatory, advertising or trading body or authority in connection with your use of our website.</li>

						</ul>
					</p>
					<h3>
						7. AVAILABILITY OF THE WEBSITE
					</h3>
					<p>
						7.1 You are responsible for making all arrangements necessary for you to have access to our website and ensuring
						that all persons who access our website through your internet connection
						are aware of these Terms and Conditions and that they comply with them.
					</p>
					<p>
						7.2 If a fault occurs in the service you should report it by email to
						{' '} <a href="mailto:contact@realis-simulation.com">contact@realis-simulation.com</a>{' '}
						and we will attempt to correct the fault as soon as we reasonably can.
					</p>
					<p> 7.3 Your access to our website may be occasionally restricted (without notice in the event of systems failure)
						to allow for repairs, maintenance or the introduction of new facilities or services.
						We will attempt to restore the service as soon as we reasonably can.
						We shall not be liable if for any reason the website is unavailable at any time or for any period.
					</p>
					<h3>
						8. UNSUBSCRIBE, SUSPENSION AND CANCELLATION OF YOUR REGISTRATION
					</h3>
					<p>
						8.1 We may suspend or cancel your registration immediately at our reasonable discretion or
						if you breach any of your obligations under these Terms and Conditions.
					</p>
					<p>
						8.2 The suspension or cancellation of your registration and your right to use our website shall not affect either party's rights or liabilities
					</p>
					<p> 8.3 You can unsubscribe at any time by informing us in writing by e-mail to
						{' '} <a href="mailto:contact@realis-simulation.com">contact@realis-simulation.com</a>.
					</p>
					<h3>
						9. Realis Simulation&rsquo;s LIABILITY
					</h3>
					<p>
						9.1 To the extent permitted by law, we exclude all conditions, warranties, representations
						or other terms which may apply to our website or any content on it, whether express or implied.
						You must bear the risks associated with the use of the Internet.
					</p>
					<p>
						9.2 Whilst we make reasonable efforts to ensure that material included on our website is accurate and complete,
						we will not be responsible for any errors or omissions or for the results obtained from the use of such information
						or for any technical problems you may experience with our website.
						If we are informed of any inaccuracies in the material on our website we will attempt to correct the inaccuracies as soon as we reasonably can.
					</p>
					<p> 9.3 We, and any Associated Companies and the officers, directors, employees, shareholders or agents of any of them,
						will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty,
						or otherwise, even if foreseeable, arising under or in connection with:
					</p>
					<p>
						<ul>
							<li>Use of, unsuitability or inability to use, our website; </li>
							<li>Use of or reliance on any content displayed on our website; or</li>
							<li>Any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful
								material that may infect your computer equipment, computer programs, data
								or other proprietary material due to your use of our site or to your downloading of any content on it, or on any website linked to it.</li>
						</ul>
					</p>
					<p>
						<b>If you are a business user</b>, please note that in particular, we will not be liable for:
					</p>
					<p>
						<ul>
							<li>Loss of profits, sales, business, data, income or revenue;</li>
							<li>Business interruption;</li>
							<li>Loss of anticipated savings;</li>
							<li>Loss of business opportunity, goodwill or reputation; or</li>
							<li>Any indirect or consequential loss or damage.</li>
						</ul>
					</p>
					<p>
						<b>If you are a consumer user</b>, please note that we only provide our site for domestic and private use.
						You agree not to use our site for any commercial or business purposes,
						and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
					</p>
					<p>
						9.5 Nothing in these Terms and Conditions shall exclude our liability for personal injury or death caused by our negligence
						(as such term if defined by the Unfair Contract Terms Act 1977)
						or misrepresentations as to a fundamental matter or any liability which cannot be excluded or limited by English law.
					</p>
					<h3>
						10. THIRD PARTY WEBSITES
					</h3>
					<p>
						Our website may include links to other websites or material which are beyond our control.
						We are not responsible for content or availability on the Internet or webpages on any other website outside our website
						and we do not endorse or make any representations about them.
						If you decide to access any of the third party websites linked to the website, you do so entirely at your own risk.
					</p>
					<h3>
						11. ADVERTISING AND SPONSORSHIP
					</h3>
					<p>
						Part of our website may contain advertising and sponsorship.
						Advertisers and sponsors are responsible for ensuring that material submitted for inclusion on our website complies with relevant laws and codes.
					</p>
					<h3>
						12. APPLICABLE LAW
					</h3>
					<p>
						These Terms and Conditions shall be governed by and construed in accordance with the laws of England and any disputes will be decided only by the English courts.
					</p>
					<h3>
						13. MISCELLANEOUS
					</h3>
					<p>
						13.1 You may not assign, sub-license or otherwise transfer any of your rights under these Terms and Conditions.
					</p>
					<p>
						13.2 If any of these Terms and Conditions is held by any court of competent authority to be unlawful, invalid or unenforceable, in whole or in part,
						this will not affect the validity of the remaining Terms and Conditions, which will continue to be valid and enforceable to the fullest extent permitted by law.
					</p>
					<p>
						13.3 No failure or delay by Realis Simulation in exercising any of its rights under these Terms and Conditions shall be deemed to be a waiver of that right
						and no waiver by Realis Simulation by any breach of these Terms and Conditions shall be considered as a waiver
						of any subsequent breach of the same or any other provision.
					</p>
					<p>
						13.4 We shall not be responsible for any breach of these Terms and Conditions caused by circumstances beyond our control.
					</p>
					<p>
						13.5 Except for Associated Companies, a person who is not a party to these Terms and Conditions shall have no right
						under the Contract (Rights of Third Parties) Act 1999 to enforce any term of these Terms and Conditions
						but this shall not affect any right or remedy of a third party which exists or is available apart from that Act.
					</p>
					<p>
						13.6 Our website is owned and operated by Realis Simulation Limited,
						a company registered in England and Wales (company registration number: 13648431) whose registered office is:
					</p>
					<p>
						Realis Simulation Ltd.<br />
						Building 50<br />
						Shoreham Technical Centre<br />
						Shoreham-by-Sea<br />
						West Sussex, BN43 5FG<br />
						United Kingdom<br />
					</p>
					<p>
						13.7 If you have any queries, please contact us at
						{' '} <a href="mailto:contact@realis-simulation.com">contact@realis-simulation.com</a>.
					</p>
					<p>
						Thank you for visiting our website.
					</p>
				</div>
			</PageSection>
		</Page>
	);
};

export default TermsPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["index", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
			banner: file(relativePath: { eq: "content/banner-connected.png" }) {
				...imageCarousel
			}
	}
`
